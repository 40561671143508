@import "../../node_modules/@coreui/coreui/scss/functions";

// Default variable overrides

$table-cell-padding-y: .3rem;
$table-cell-padding-x: .3rem;

table {
    font-size: .9rem
}

// $btn-padding-y: $input-.1rem;




// Required
@import "../../node_modules/@coreui/coreui/scss/variables";
@import "../../node_modules/@coreui/coreui/scss/tables";
@import "../../node_modules/@coreui/coreui/scss/maps";
@import "../../node_modules/@coreui/coreui/scss/mixins";
@import "../../node_modules/@coreui/coreui/scss/root";

// Optional CoreUI components here
@import "../../node_modules/@coreui/coreui/scss/reboot";
@import "../../node_modules/@coreui/coreui/scss/type";